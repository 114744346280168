


































import { MutationPayload } from 'vuex';
import { defineComponent, ref } from '@vue/composition-api';
import { getTrend } from '@/api/trends';
import { ITrendProduct } from '@/models/trends';
import TrendCard from '@/partials/TrendCard.vue';
import ProductCard from '@/partials/ProductCard.vue';
import { IProductItem } from '@/models/product';
import store from '@/store';
import useContext, { IUseContext } from '@/composition/context';
import router from '@/router';

export default defineComponent({
  components: {
    TrendCard,
    ProductCard,
  },
  setup() {
    const {
      contextVisitor,
      translations,
      contextEvent,
      language,
    } = (useContext() as unknown) as IUseContext;

    const trend = ref();
    const event = parseInt(router.currentRoute.params.event, 10);
    const trendId = parseInt(router.currentRoute.params.trend, 10);
    const getProduct = (trendProduct: ITrendProduct) => {
      const config: IProductItem = {
        id: trendProduct.id,
        title: trendProduct.product_title,
        location: trendProduct.product_location,
        image_1: trendProduct.product_image,
        type: trendProduct.product_type,
      };

      return config;
    };
    if (contextEvent.value.id) {
      getTrend(trendId).then((response) => {
        trend.value = response.data;
      });
    }
    store.subscribe((mutation: MutationPayload) => {
      if (mutation.type === 'setContextEvent') {
        getTrend(trendId).then((response) => {
          trend.value = response.data;
        });
      }
    });
    return {
      getProduct,
      trend,
      event,
      contextVisitor,
      translations,
      contextEvent,
      language,
    };
  },
});
